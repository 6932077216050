// extracted by mini-css-extract-plugin
export var articleContent = "index-module--articleContent--NhNfp";
export var articleContinueTitle = "index-module--articleContinueTitle--gEvHd";
export var articleDiv = "index-module--articleDiv--LyCHa";
export var articleReleasedDate = "index-module--articleReleasedDate--CiGQV";
export var articleTag = "index-module--articleTag--uLqm1";
export var articleTitle = "index-module--articleTitle--SOEZx";
export var backgroundPatternCustomBio = "index-module--backgroundPatternCustomBio--kz8SL";
export var customBio = "index-module--customBio--IL3RQ";
export var postsHeaderDiv = "index-module--postsHeaderDiv--PafvU";
export var recentlyArticleContent = "index-module--recentlyArticleContent--GrqwR";
export var recentlyArticleContinueTitle = "index-module--recentlyArticleContinueTitle--MqvmW";
export var recentlyArticleDiv = "index-module--recentlyArticleDiv--ofS0S";
export var recentlyArticleReleasedDate = "index-module--recentlyArticleReleasedDate--Gwbin";
export var recentlyArticleTag = "index-module--recentlyArticleTag--H7eQr";
export var recentlyArticleTitle = "index-module--recentlyArticleTitle--QGVb1";
export var tagsHeaderDiv = "index-module--tagsHeaderDiv--Bhuoa";
export var wrapperDiv = "index-module--wrapperDiv--DzbLO";