import React from "react"
import _ from "lodash"
import { Link, graphql } from "gatsby"
import { navigate } from "gatsby"
import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TagsImage from "../utils/tags.png"
import RecentlyImage from "../utils/recently.png"
import AllPostsImage from "../utils/posts.png"
import * as styles from "./index.module.css"
import * as tagStyles from "./tags.module.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  let posts = data.posts.edges
  const tags = data.tags.distinct

  function selectArticle(slug) {
    navigate(slug)
  }

  function combineTags(tagsArticle) {
    let result = ""
    if (tagsArticle) {
      tagsArticle.forEach((element) => {
        let tagArticle = element.replace(/\s/g, "")
        result += "#" + tagArticle + " "
      })
    }
    return result
  }

  const recentlyPost = posts[0]
  let recentlyView

  if (recentlyPost) {
    const title =
      recentlyPost.node.frontmatter.title || recentlyPost.node.fields.slug

    recentlyView = (
      <div>
        <div className={styles.postsHeaderDiv}>
          <img src={RecentlyImage} alt="tags" width="30" height="30"></img>
          <p>Recently post</p>
        </div>

        <div className={styles.wrapperDiv}>
          <article
            className={styles.recentlyArticleDiv}
            key={recentlyPost.node.fields.slug}
            onClick={() => selectArticle(recentlyPost.node.fields.slug)}
          >
            <Link
              className={styles.recentlyArticleTitle}
              to={recentlyPost.node.fields.slug}
            >
              {title}
            </Link>

            <p className={styles.recentlyArticleReleasedDate}>
              {recentlyPost.node.frontmatter.date}
            </p>

            <section>
              <p
                className={styles.recentlyArticleContent}
                dangerouslySetInnerHTML={{
                  __html:
                    recentlyPost.node.frontmatter.description ||
                    recentlyPost.node.excerpt,
                }}
              />
              <p className={styles.recentlyArticleContinueTitle}>
                Continue reading...
              </p>
            </section>
          </article>
        </div>
      </div>
    )

    // Remove recently post from posts list
    posts = posts.filter((item) => item !== recentlyPost)
  }

  return (
    <Layout
      location={location}
      title={siteTitle}
      metaData={data.site.siteMetadata}
    >
      <SEO title="Dang's blog" />
      <div className={styles.customBio}>
        <Bio />
      </div>

      <div className={styles.tagsHeaderDiv}>
        <img src={TagsImage} alt="tags" width="30" height="30"></img>
        <p>Tags</p>
      </div>
      <div className={tagStyles.tagsDiv}>
        {tags.map((tag) => {
          if (tag === "travel") {
            return (
              <Link
                key={tag}
                className={tagStyles.tag}
                to={`/${_.kebabCase(tag)}`}
              >
                {tag}
              </Link>
            )
          }
          return (
            <Link
              key={tag}
              // style={{ textDecoration: "none" }}
              className={tagStyles.tag}
              to={`/tags/${_.kebabCase(tag)}`}
            >
              {tag}
            </Link>
          )
        })}

        <Link key="allTag" className={tagStyles.viewAllTag} to={`/tags`}>
          All →
        </Link>
      </div>

      {/* Recently post */}
      {recentlyView}

      <div className={styles.postsHeaderDiv}>
        <img src={AllPostsImage} alt="tags" width="30" height="30"></img>
        <p>All posts</p>
      </div>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <div className={styles.wrapperDiv}>
            <article
              className={styles.articleDiv}
              key={node.fields.slug}
              onClick={() => selectArticle(node.fields.slug)}
            >
              <Link className={styles.articleTitle} to={node.fields.slug}>
                {title}
              </Link>

              <p className={styles.articleReleasedDate}>
                {node.frontmatter.date}
              </p>

              <section>
                <p
                  className={styles.articleContent}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
                {/* <p className={styles.articleContinueTitle}>Continue...</p> */}

                <p className={styles.articleTag}>
                  {combineTags(node.frontmatter.tags)}
                </p>
              </section>
            </article>
          </div>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
          }
        }
      }
    }

    tags: allMarkdownRemark {
      distinct(field: frontmatter___tags)
    }
  }
`
